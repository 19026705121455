import React, { useState, useEffect } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import { Button, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Tooltip } from "@mui/material";

const AdminPanel = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [paginationMetadata, setPaginationMetadata] = useState({
    page: 1,
    pageSize: 20,
    total: 1, // Corrected to reflect the total number of items
  });

  useEffect(() => {
    loadQuestions(); // Load questions whenever the component mounts or page changes
  }, [paginationMetadata.page]); // Trigger loadQuestions when page changes

  const loadQuestions = async () => {
    setFetching(true);
    try {
      const ip = Constants.ip;
      const port = Constants.port;
      const endpoint = `/get_questions?page=${paginationMetadata.page}&pageSize=${paginationMetadata.pageSize}`;
      const res = await Photon.get(ip, port, endpoint);

      setQuestions(res.data.data);
      setPaginationMetadata((curr) => ({
        ...curr,
        total: res.data.totalCount, // Set total number of items correctly
      }));
      populateTable(res.data.data || []);
    } catch (error) {
      setFetching(false);
      console.error("Error fetching questions:", error);
    } finally {
      setFetching(false);
    }
  };

  const makeDataSource = (questions) => {
    return questions.map((question, index) => ({
      key: (index + 1).toString(),
      question_id: question.question_id,
      question_name: question.question_name,
      difficulty: question.difficulty,
      companytag: question.companytag,
      coming_soon: question.coming_soon,
    }));
  };

  const callPage = (e) => {
    const id = e.currentTarget.dataset.id;
    navigate("/admin/questions/" + id);
  };

  const populateTable = (questions) => {
    const newColumns = [
      {
        title: "ID",
        dataIndex: "question_id",
        key: "question_id",
        render: (text, record) => record.question_id,
      },
      {
        title: "Questions",
        dataIndex: "question_name",
        key: "question_name",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {record.coming_soon ? (
              <>
                <span>{record.question_name}</span>
                <Tag color="orange" key={record.question_id}>
                  Coming Soon
                </Tag>
              </>
            ) : (
              <a
                data-id={record.question_id}
                data-name={record.question_name}
                onClick={callPage}
              >
                {record.question_name}
              </a>
            )}
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <Tooltip title="Edit question">
            <ModeEditIcon
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/admin/questions/${record.question_id}`)}
            />
          </Tooltip>
        ),
      },
    ];

    setColumns(newColumns);
    setDataSource(makeDataSource(questions));
  };

  const handlePaginationChange = (page) => {
    setPaginationMetadata((prev) => ({
      ...prev,
      page, // Set the page number when user changes the page
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "2rem 0",
        width: "100%",
        height: "100%",
        overflow: "auto",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "60%",
          margin: "0 auto",
        }}
      >
        <Button
          onClick={() => {
            navigate("/admin/questions/add");
          }}
          type="primary"
        >
          Add Question
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2rem 0",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={fetching}
          style={{
            minWidth: "60%",
          }}
          pagination={{
            current: paginationMetadata.page,
            pageSize: paginationMetadata.pageSize,
            total: paginationMetadata.total,
            onChange: handlePaginationChange, // Now triggers page change correctly
            showTotal: (total) => `Total ${total} items`, // Display total items
          }}
        />
      </div>
    </div>
  );
};

export default AdminPanel;
