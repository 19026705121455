import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import Paper from "@mui/material/Paper";

import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import MarkdownAnswer from "./MarkdownAnswer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./../../css/chat-box.css";
import base64 from "react-native-base64";
import { CircularProgress, Tooltip } from "@mui/material";

const ChatBox = (props) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [fetchingChats, setFetchingChats] = useState(true);
  const [chatWidth, setChatWidth] = useState("24%");
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null); // Ref for the bottom of the chat

  useEffect(() => {
    fetchChatHistory();
  }, [props.questionData?.id]);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Fetch chat history from the database
  const fetchChatHistory = async () => {
    try {
      if (!props.questionData?.id) return;
      setFetchingChats(true);
      let ip = Constants.ip;
      let port = Constants.port;

      let endpoint = `/questions/chats/${props.questionData.id}`;

      let res = await Photon.get(ip, port, endpoint, {});

      const dialogues =
        res.data.dialogues?.map((dialogue) => {
          return {
            user: dialogue.entity,
            text: base64.decode(dialogue.text),
          };
        }) || [];

      if (dialogues.length === 0) {
        dialogues.push({
          user: "AI",
          text: "Hi! I'm your AI coding assistant. How can I help you?",
        });
      }

      setMessages(dialogues);
      setFetchingChats(false);
    } catch (error) {
      setFetchingChats(false);
      console.error("Error: ", error);
    }
  };

  // Simulates an API call
  const callApi = async (question) => {
    try {
      let ip = Constants.ip;
      let port = Constants.port;
      let endpoint = "/openai";

      // encode user program to base64
      const encodedUserCode = btoa(props.userProgram);

      let obj = {
        // base 64 encoded user code
        question_id: props.questionData.id,
        language: props.language,
        userCode: encodedUserCode,
        query: question,
      };

      let res = await Photon.post(ip, port, endpoint, obj);

      return res.data.content;
    } catch (error) {
      console.error("Error: ", error);
      return "Sorry, I am unable to answer your question at the moment.";
    }
  };

  // Handles user input submission
  const handleSubmit = async () => {
    const userMessage = input.trim();
    if (!userMessage) return;

    // Add user message to the chat
    setMessages((prevState) => {
      let messages = prevState;
      if (messages.length === 1 && messages[0].user === "AI") {
        messages = [];
      }

      return [...messages, { user: "USER", text: userMessage }];
    });

    setInput("");
    setIsTyping(true);

    const response = await callApi(userMessage);

    // Add AI response to the chat
    setMessages((prevState) => {
      return [...prevState, { user: "AI", text: response }];
    });

    setIsTyping(false);
  };

  // Handles Enter key press
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const containerStyles = {
    minWidth: props.fullScreenEditor || !props.showChat ? "0%" : "100%",
    width: props.fullScreenEditor || !props.showChat ? "0%" : "100%",
    maxWidth: props.fullScreenEditor || !props.showChat ? "0%" : "100%",
    height: "100%",
    flex: 1,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    transition: "width 0.5s",
    border: "none",
    overflowY: "auto !important",
  };

  if (fetchingChats) {
    return (
      <Paper
        style={{
          ...containerStyles,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={20} />
        <h4 style={{ marginTop: "20px", fontSize: "1rem", color: "#4a4a4a" }}>
          Initializing Chat...
        </h4>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} sx={{ p: 0, ...containerStyles }}>
      <div
        style={{
          backgroundColor: "#f9f9f9",
          borderRadius: "5px 5px 0 0",
          padding: "10px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              color: "#4a4a4a", // Slate-like color
            }}
          >
            AI Coding Assistant
          </span>
          <div
            style={{
              fontSize: "0.85rem",
              color: "#6d6d6d", // Subtle grey
            }}
          >
            Get hints, explanations, or debugging help!
          </div>
        </div>
        <Tooltip title="Hide chat" arrow>
          <KeyboardDoubleArrowRightIcon
            style={{
              fontSize: "1.5rem",
              cursor: "pointer",
              color: "#6d6d6d", // Match the subtle grey
            }}
            onClick={() => props.toggleChat()}
          />
        </Tooltip>
      </div>

      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "10px",
          backgroundColor: "#f9f9f9",
          borderRadius: "5px 5px 0 0",
        }}
        ref={chatContainerRef}
      >
        {messages.map((item, index) => (
          <div
            key={index}
            style={{
              textAlign: item.user === "USER" ? "right" : "left",
              margin: "10px 0",
            }}
          >
            <MarkdownAnswer text={item.text} user={item.user} />
          </div>
        ))}
        {isTyping && (
          <div style={{ textAlign: "left", margin: "10px 0" }}>
            <div className="dots-animation">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div style={{ borderTop: "1px solid #ccc", padding: "10px" }}>
        <Input
          type="text"
          placeholder="Ask AI Chat Bot..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={keyPress}
        />
      </div>
    </Paper>
  );
};

export default ChatBox;
