import React, { useEffect, useState } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import { Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import "../../css/style.css";
import { useSelector } from "react-redux";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TonalityIcon from "@mui/icons-material/Tonality";
import { Tooltip } from "@mui/material";

function ListQuestions(props) {
  const authState = useSelector((state) => state.auth);
  const [questions, setQuestions] = useState([]);
  const [teamId, setTeamId] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [isUserPartOfAnyTeam, setIsUserPartOfAnyTeam] = useState(false);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();

  const makeDataSource = () => {
    console.log("Inside make data source");
    const newDataSource = [];
    for (let i = 0; i < questions.length; i++) {
      newDataSource.push({
        key: (i + 1).toString(),
        question_id: questions[i].question_id,
        accepted: questions[i].accepted,
        attempted: questions[i].attempted,
        question_name: questions[i].question_name,
        difficulty: questions[i].difficulty,
        companytag: questions[i].companytag,
        coming_soon: questions[i].coming_soon,
      });
    }
    return newDataSource;
  };

  const callPage = (e) => {
    console.log(
      "change page teams called." +
        e.currentTarget.dataset.id +
        " " +
        e.currentTarget.dataset.name
    );
    const id = e.currentTarget.dataset.id;
    const name = e.currentTarget.dataset.name;
    navigate("/questions/" + id);
  };

  const populateTable = () => {
    let newDataSource = [];

    const newColumns = [
      {
        title: "ID",
        dataIndex: "question_id",
        key: "question_id",
        render: (text, record, index) => record.question_id,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "accepted",
        render: (text, record, index) => {
          if (record.accepted) {
            return (
              <Tooltip title="Solved">
                <DoneAllIcon
                  style={{
                    color: "green",
                    fontSize: "1.3rem",
                  }}
                />
              </Tooltip>
            );
          } else if (record.attempted) {
            return (
              <Tooltip title="Attempted">
                <TonalityIcon
                  style={{
                    color: "orange",
                    fontSize: "1.3rem",
                  }}
                />
              </Tooltip>
            );
          } else {
            return <></>;
          }
        },
      },
      {
        title: "Title",
        dataIndex: "question_name",
        key: "question_name",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {record.coming_soon ? (
              <>
                <span>{record.question_name}</span>
                <Tag color="orange" key={record.question_id}>
                  Coming Soon
                </Tag>
              </>
            ) : (
              <a
                data-id={record.question_id}
                data-name={record.question_name}
                onClick={callPage}
              >
                {record.question_name}
              </a>
            )}
          </div>
        ),
      },
      {
        title: "Difficulty",
        dataIndex: "difficulty",
        key: "difficulty",
        render: (text) => {
          let color = "green";
          if (text === "easy") {
            color = "green";
          } else if (text === "medium") {
            color = "geekblue";
          } else if (text === "hard") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={text}>
              {text}
            </Tag>
          );
        },
      },
      {
        title: "Company",
        dataIndex: "companytag",
        key: "companytag",
        render: (tags) => (
          <>
            {tags.map((tag) => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "google") {
                color = "green";
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
    ];
    newDataSource = makeDataSource();
    setColumns(newColumns);
    setDataSource(newDataSource);
    console.log("data source");
    console.log(dataSource);
    console.log("columns");
    console.log(columns);
  };

  const fetchQuestions = async () => {
    console.log("inside fetch fnxx " + teamId);
    let ip = Constants.ip;
    // let ip = "http://localhost:";
    let port = Constants.port;
    let endpoint = "/get_questions_by_language";
    let obj = { language: "motoko" };
    console.log(JSON.stringify(obj));
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log("after post request");
    setQuestions(res.data.questions);
  };

  const getTeamIdFromEmailId = async (email) => {
    let ip = Constants.ip;
    // let ip = "http://localhost:";
    let port = Constants.port;
    let endpoint = "/get_teamid_from_email";
    let obj = { email: email };
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res.data));
    setTeamId(res.data.team_id);

    // New user show alert to join/create team
    if (!res.data.status) {
      setIsUserPartOfAnyTeam(true);
    }
    return res.data.team_id;
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    console.log(questions);
    populateTable();
  }, [questions]);

  useEffect(() => {
    if (authState.userDetails?.email) {
      getTeamIdFromEmailId(authState.userDetails?.email);
    }
  }, [authState.userDetails?.email]);

  return (
    <div className="listquestions">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSize: 20,
        }}
      />
    </div>
  );
}

export default ListQuestions;
