import React, { Component } from "react";
import Header from "../header";
import ListQuestions from "./listquestions";

export class DashboardPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ListQuestions history={this.props.history} />;
  }
}

export default DashboardPage;
