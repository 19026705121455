import React, { useEffect } from "react";

import LandingPageHeader from "./LandingPageHeader";
import HeroSection from "./HeroSection";
import DeveloperSection from "./DeveloperSection";
import PricingSection from "./PricingSection";
import Footer from "../Footer/index";

import "./landing-page-styles.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "./LandingPageHelper/home/assets/fonts/fonts.css"; // Import font styles
import "./LandingPageHelper/home/assets/css/all.min.css"; // Import all.min.css
import "./LandingPageHelper/home/assets/css/default.css"; // Import default.css
import "./LandingPageHelper/home/style.css"; // Import your style.css
import "./LandingPageHelper/home/assets/css/responsive.css"; // Import responsive.css
import "./LandingPageHelper/home/bootstrap.min.css"; // Import Bootstrap CSS
import "./LandingPageHelper/home/pricing.css"; // Import pricing.css

const heroSectionHeading =
  "Level up your ICP skills with coding challenges and contests";

const heroSectionSubHeading =
  "Dive into the world of Internet Computer Protocol (ICP) by solving coding challenges. Practice in our interactive online editor and level up your programming skills.";

const developerSectionHeading = "Developer";
const developerSectionSubHeading =
  "Empowering developers to excel on the Internet Computer, we support coding in Motoko—the language at the heart of the ICP ecosystem.  With our interactive online editor, you can learn, practice, and enhance your understanding of Motoko and ICP concepts in a guided environment.";

const headerLinks = [
  {
    text: "Developer",
    link: "#developer",
  },
  {
    text: "Pricing",
    link: "#pricing",
  },
];

const LandingPage = () => {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.authenticated) {
      navigate("/dashboard");
    }
  }, [authState.authenticated]);

  return (
    <div className="landing-page-container">
      <LandingPageHeader headerLinks={headerLinks} />
      <HeroSection
        headingText={heroSectionHeading}
        subHeadingText={heroSectionSubHeading}
      />
      <DeveloperSection
        headingText={developerSectionHeading}
        subHeadingText={developerSectionSubHeading}
      />
      <PricingSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
