import React from "react";

const HorizontalResizer = ({
  resizableStyle,
  left,
  handleResize,
  boxReference,
}) => (
  <div
    style={{ ...resizableStyle, ...(left ? { left: 0 } : { right: 0 }) }}
    onMouseDown={(e) => {
      const startX = e.clientX;
      const handleMouseMove = (e) => {
        handleResize(
          boxReference,
          ((e.clientX - startX) / window.innerWidth) * 100
        );
      };
      const handleMouseUp = () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }}
  />
);

export default HorizontalResizer;
