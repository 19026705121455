import React, { useState, useEffect } from "react";
import QuestionBox from "./question_box";
import EditorBox from "./editor_box";
import ChatBox from "./chat_box";
import "../../css/style.css";
import { useParams, useNavigate } from "react-router-dom";
import RenderCodeOutput from "./RenderCodeOutput";
import { CodeOutlined } from "@ant-design/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Tooltip } from "@mui/material";
import HorizontalResizer from "../Resizer/HorizontalResizer";
import base64 from "react-native-base64";
import isBase64 from "../../utils/isBase64";
import Photon from "../Photon/photon";
import * as Constants from "../Config/constants";

const initialQuestionBoxWidth = 25; // in percentage, 25% of total width
const initialEditorBoxWidth = 50;
const initialChatBoxWidth = 25;

const QuestionPage = () => {
  const { ip, port } = Constants;
  const { id } = useParams();
  const navigate = useNavigate();

  const [questionData, setQuestionData] = useState({
    id: isNaN(id) ? null : Number(id),
    name: "",
    detail: "",
    questionDescription: "",
    base64Question: "",
    solution: "",
  });

  const [selectedLanguage, setSelectedLanguage] = useState("motoko");
  const [userProgram, setUserProgram] = useState({
    motoko: "",
  });
  const [showChat, setShowChat] = useState(true);
  const [fullScreenEditor, setFullScreenEditor] = useState(false);
  const [runningTestCases, setRunningTestCases] = useState(false);
  const [isResultMinimized, setIsResultMinimized] = useState(false);
  const [output, setOutput] = useState({});

  const [widths, setWidths] = useState({
    questionBox: initialQuestionBoxWidth,
    editorBox: initialEditorBoxWidth,
    chatBox: initialChatBoxWidth,
  });

  useEffect(() => {
    fetchQuestion(id);
    fetchSolution(id);
  }, []);

  const handleResize = (type, delta) => {
    const totalWidth = 100; // Total width in percentage
    const minEditorWidth = 30; // Minimum width for the editor in percentage
    const minChatWidth = 10; // Minimum width for the chat box in percentage
    const minQuestionWidth = 10; // Minimum width for the question box in percentage

    if (type === "questionBox") {
      const newQuestionWidth = Math.max(
        minQuestionWidth,
        widths.questionBox + delta
      );
      const newEditorWidth = totalWidth - newQuestionWidth - widths.chatBox;

      if (newEditorWidth >= minEditorWidth) {
        setWidths({
          questionBox: newQuestionWidth,
          editorBox: newEditorWidth,
          chatBox: widths.chatBox,
        });
      }
    } else if (type === "editorBoxLeft") {
      const newQuestionWidth = Math.max(
        minQuestionWidth,
        widths.questionBox - delta
      );
      const newEditorWidth = widths.editorBox + delta;

      if (
        newEditorWidth >= minEditorWidth &&
        newQuestionWidth >= minQuestionWidth
      ) {
        setWidths({
          questionBox: newQuestionWidth,
          editorBox: newEditorWidth,
          chatBox: widths.chatBox,
        });
      }
    } else if (type === "editorBoxRight") {
      const newEditorWidth = Math.max(minEditorWidth, widths.editorBox + delta);
      const newChatWidth = totalWidth - widths.questionBox - newEditorWidth;

      if (newChatWidth >= minChatWidth) {
        setWidths({
          questionBox: widths.questionBox,
          editorBox: newEditorWidth,
          chatBox: newChatWidth,
        });
      }
    } else if (type === "chatBox") {
      const newChatWidth = Math.max(minChatWidth, widths.chatBox + delta);
      const newEditorWidth = totalWidth - widths.questionBox - newChatWidth;

      if (newEditorWidth >= minEditorWidth && newChatWidth >= minChatWidth) {
        setWidths({
          questionBox: widths.questionBox,
          editorBox: newEditorWidth,
          chatBox: newChatWidth,
        });
      }
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 64px)", // Adjust for header height
    background: "#fafafa",
    gap: "2px",
    padding: "8px",
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    background: "#fff",
    border: "1px solid #ddd",
    borderRadius: "10px",
    margin: "2px 0px 2px 2px",
    position: "relative",
  };

  const resizableStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "8px",
    cursor: "ew-resize",
    zIndex: 10,
  };

  const fetchQuestion = async (questionId) => {
    let res = await Photon.post(ip, port, "/get_question_by_id", {
      id: questionId,
    });

    if (res.status === 404) {
      // question not found
      navigate("/dashboard");
      return;
    }

    const question = res.data.question;
    const decodedQuestion = base64.decode(question);
    setQuestionData((data) => ({
      ...data,
      name: res.data.name,
      questionDescription: decodedQuestion,
      base64Question: question,
      accepted: res.data.accepted,
      difficulty: res.data.difficulty,
    }));
  };

  const fetchSolution = async (questionId) => {
    let res = await Photon.post(ip, port, "/get_solution_by_id", {
      id: questionId,
    });

    setQuestionData((data) => ({
      ...data,
      solution: isBase64(res.data.solution)
        ? base64.decode(res.data.solution)
        : res.data.solution,
    }));
  };

  const toggleFullScreenEditor = () => {
    setFullScreenEditor((currentState) => !currentState);
  };

  const toggleChat = () => {
    // currently show chat - now hide chat
    if (showChat) {
      setWidths((curr) => ({
        ...curr,
        editorBox: curr.editorBox + curr.chatBox,
        chatBox: 0,
      }));
    } else {
      setWidths((curr) => ({
        ...curr,
        editorBox: curr.editorBox - initialChatBoxWidth,
        chatBox: initialChatBoxWidth,
      }));
    }

    setShowChat((state) => !state);
  };

  const handleQuestionDetailsChange = (key, value) => {
    setQuestionData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div style={containerStyle}>
        {/* QuestionBox */}
        <div
          style={{
            ...boxStyle,
            width: fullScreenEditor ? "0%" : `${widths.questionBox}%`,
            flexShrink: 0,
          }}
        >
          <QuestionBox
            questionData={questionData}
            setQuestionData={setQuestionData}
            navigate={navigate}
            fullScreenEditor={fullScreenEditor}
            setFullScreenEditor={setFullScreenEditor}
            handleQuestionDetailsChange={handleQuestionDetailsChange}
          />
          <HorizontalResizer
            left={false}
            resizableStyle={resizableStyle}
            handleResize={handleResize}
            boxReference="questionBox"
          />
        </div>

        {/* EditorBox */}
        <div
          style={{
            ...boxStyle,
            width: fullScreenEditor ? "100%" : `${widths.editorBox}%`,
            flexShrink: 0,
            border: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              background: "#fafafa ",
              gap: "4px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                height: isResultMinimized ? "100%" : "70%",
                borderRadius: "10px",
                border: "1px solid #ddd",
              }}
            >
              <EditorBox
                questionData={questionData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                userProgram={userProgram[selectedLanguage]}
                setUserProgram={setUserProgram}
                navigate={navigate}
                questionId={questionData?.id}
                showChat={showChat}
                showChatButton={true}
                toggleChat={toggleChat}
                fullScreenEditor={fullScreenEditor}
                setFullScreenEditor={setFullScreenEditor}
                toggleFullScreenEditor={toggleFullScreenEditor}
                setOutput={setOutput}
                output={output}
                runningTestCases={runningTestCases}
                setRunningTestCases={setRunningTestCases}
                setIsResultMinimized={setIsResultMinimized}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: isResultMinimized ? "0%" : "30%",
                background: "#fff",
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: isResultMinimized ? "0" : "8px 12px",
                overflow: "hidden",
                transition: "height 0.5s ease",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "20%",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                }}
              >
                <h6
                  style={{ fontSize: ".9rem", cursor: "pointer" }}
                  onClick={() => setIsResultMinimized(true)}
                >
                  <CodeOutlined
                    style={{ marginRight: ".2rem", color: "green" }}
                  />
                  Code output
                </h6>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "1rem",
                    color: "gray",
                  }}
                  onClick={() => setIsResultMinimized(true)}
                >
                  <Tooltip title="minimize output">
                    <KeyboardArrowDownIcon />
                  </Tooltip>
                </button>
              </div>
              <RenderCodeOutput
                runningTestCases={runningTestCases}
                output={output}
              />
            </div>
            <div
              style={{
                position: "fixed",
                bottom: 10,
                width: fullScreenEditor ? "98%" : `${widths.editorBox - 2}%`,
                marginLeft: ".2rem",
                height: isResultMinimized ? "40px" : "0",
                background: "#fff",
                borderTop: "1px solid #ddd",
                display: isResultMinimized ? "flex" : "none",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
                transition: "height 0.5s ease",
                padding: "12px 0 6px 12px",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
              onClick={() => setIsResultMinimized(false)}
            >
              <h6
                style={{
                  fontSize: ".9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <CodeOutlined style={{ color: "green" }} />
                Code output
                <Tooltip title="show output">
                  <KeyboardArrowUpIcon style={{}} />
                </Tooltip>
              </h6>
            </div>
          </div>
          <HorizontalResizer
            left={false}
            resizableStyle={resizableStyle}
            handleResize={handleResize}
            boxReference="editorBoxLeft"
          />
          <HorizontalResizer
            left={false}
            resizableStyle={resizableStyle}
            handleResize={handleResize}
            boxReference="editorBoxRight"
          />
        </div>

        {/* ChatBox */}
        <div
          style={{
            ...boxStyle,
            width: fullScreenEditor ? "0%" : `${widths.chatBox}%`,
            flexShrink: 0,
          }}
        >
          <ChatBox
            questionData={questionData}
            language={selectedLanguage}
            userProgram={userProgram}
            showChat={showChat}
            toggleChat={toggleChat}
            fullScreenEditor={fullScreenEditor}
            setFullScreenEditor={setFullScreenEditor}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionPage;
