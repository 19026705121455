import React, { useEffect, useRef, useState } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import Editor from "@monaco-editor/react";

import { Button, Select } from "antd";

import { Tabs } from "antd";
import Paper from "@mui/material/Paper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "../../css/style.css";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { configureMonaco } from "../../utils/monacoConfigs";
import { loadMoc } from "../../workers/mocShim";
import { loadBasePackage } from "../../workers/file";
import FullScreenToggleButtons from "./FullScreenToggleButtons";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

const { TabPane } = Tabs;

const { Option } = Select;

const EditorBox = (props) => {
  const [motoko, setMotoko] = useState(null);
  const [monaco, setMonaco] = useState(null);
  const [syntaxErrors, setSyntaxErrors] = useState([]);

  const authState = useSelector((state) => state.auth);
  const editorRef = useRef();

  useEffect(() => {
    if (!props.addQuestion) call_to_fecthCode(null, props.questionData.id);
  }, [props.questionData?.id]);

  useEffect(() => {
    checkCodeMarkers(props.userProgram);
  }, [props.userProgram]);

  const loadMotoko = async () => {
    const Motoko = await loadMoc();
    setMotoko(Motoko);
    await loadBasePackage(Motoko);
  };

  const setMarkers = (diags, codeModel, monaco) => {
    if (!monaco) return;
    const markers = [];
    diags.forEach((d) => {
      const severity =
        d.severity === 1
          ? monaco.MarkerSeverity.Error
          : monaco.MarkerSeverity.Warning;
      const marker = {
        startLineNumber: d.range.start.line + 1,
        startColumn: d.range.start.character + 1,
        endLineNumber: d.range.end.line + 1,
        endColumn: d.range.end.character + 1,
        message: d.message,
        code: d.code,
        severity,
      };
      markers.push(marker);
    });

    monaco.editor.setModelMarkers(codeModel, "moc", markers);
  };

  const checkCodeMarkers = async (code) => {
    if (!motoko || !monaco) return;
    motoko.saveFile("temp.mo", code);

    const checks = await motoko.check("temp.mo");
    const diags = checks.diagnostics;
    setSyntaxErrors(diags);

    // Handle the checks and update your markers
    const codeModel = monaco.editor.getModels()[0];
    setMarkers(diags, codeModel, monaco);
  };

  // const initialFetchAndSetData = async () => {
  //   const user_dataa = auth.get_user_data();
  //   console.log("user_data inside chatbox=== " + JSON.stringify(user_dataa));
  //   setState((state) => ({
  //     ...state,
  //     tokenId: state.tokenId,
  //     user_data: user_dataa,
  //   }));
  //   // call_to_fecthCode(null, props.questionData.id);
  //   let ip = Constants.ip;
  //   //let ip = "http://localhost:";
  //   let port = Constants.port;
  //   let endpoint = "/get_team_by_id";
  //   let obj = { email: state.user_data?.email };
  //   if (!state.user_data?.email) return;
  //   const team_members = await Photon.post(ip, port, endpoint, obj);
  //   let team_members_data = team_members.data.team_members_data;
  //   team_members_data = await remove_self_user(team_members_data);
  //   setState((state) => ({ ...state, team_members: team_members_data }));
  // };

  // const remove_self_user = (team_members_data) => {
  //   let team = [];
  //   for (let i = 0; i < team_members_data.length; i++) {
  //     if (team_members_data[i].email != state.user_data?.email) {
  //       team.push(team_members_data[i]);
  //     }
  //   }
  //   console.log("team without self" + JSON.stringify(team));
  //   return team;
  // };

  const selectLanguage = (value) => {
    console.log(value);

    props.setSelectLanguage(value);
    call_to_fecthCode(value);
  };

  const call_to_fecthCode = async (language, questionId) => {
    try {
      const codeLanguage = language || props.selectedLanguage;
      let obj = {
        user_id: authState.userDetails?.email,
        code_language: codeLanguage,
        question_id: questionId || props.questionId,
      };

      if (!obj.question_id) return;
      let ip = Constants.ip;
      let port = Constants.port;
      let endpoint = "/fetchCode";

      let res = await Photon.post(ip, port, endpoint, obj);
      let decodedProgram = base64.decode(res.data.code || "");
      props.setUserProgram({
        [codeLanguage]: decodedProgram,
      });
    } catch (error) {
      console.log("Error in fetching code", error);
    }
  };

  const call_to_submit_function = async () => {
    let encodedProgram = base64.encode(props.userProgram);
    let ip = Constants.ip;
    let port = Constants.port;
    let endpoint = "/submit_code";
    let obj = {
      user_id: authState.userDetails?.email,
      code: encodedProgram,
      code_language: props.selectedLanguage,
      question_id: props.questionId,
      type: "submit",
    };
    let res = await Photon.post(ip, port, endpoint, obj);
    props.setOutput(res.data.output);
  };

  const call_to_runtest_function = async () => {
    props.setIsResultMinimized(false);
    if (
      syntaxErrors.length > 0 &&
      // if any syntax error is error and not warning
      syntaxErrors.some((error) => error.severity === 1)
    ) {
      props.setOutput({
        execution_status: "syntax_error",
        errors: syntaxErrors,
      });

      return;
    }

    props.setRunningTestCases(true);

    try {
      console.log(props.selectedLanguage);
      props.setIsResultMinimized(false);
      let code_language = props.selectedLanguage;

      let encodedProgram = base64.encode(props.userProgram);
      let ip = Constants.ip;
      let port = Constants.port;
      let endpoint = "/submit_code";

      let obj = {
        user_id: authState.userDetails?.email,
        code: encodedProgram,
        code_language: code_language,
        question_id: props.questionId,
        driver_code: props.editMode
          ? base64.encode(props.driverProgram || "")
          : null,
        type: props.editMode ? "run" : "submit",
      };
      let res = await Photon.post(ip, port, endpoint, obj);

      props.setOutput(res.data.output);
      //state.output = res.data.output;
      //let res = res1.json();
    } catch (error) {
      console.log("Error in run test", error);
    } finally {
      props.setRunningTestCases(false);
    }
  };

  const onChange = async (newValue) => {
    const selectedLanguage = props.selectedLanguage;

    if (props.editMode && props.activeTab === "driverCode") {
      props.setDriverProgram((curr) => ({
        ...curr,
        [selectedLanguage]: newValue,
      }));
    } else {
      props.setUserProgram((curr) => ({
        ...curr,
        [selectedLanguage]: newValue,
      }));
    }
  };

  const beforeMountConfiguration = async (monaco) => {
    await loadMotoko();
    configureMonaco(monaco);
  };

  const callback = async (key) => {
    if (key != 1) {
      console.log(key);
      let obj = {
        user_id: key,
        code_language: props.selectedLanguage,
        question_id: props.questionId,
      };
      let ip = Constants.ip;
      //let ip = "http://localhost:";
      let port = Constants.port;
      let endpoint = "/fetchCode";

      let res = await Photon.post(ip, port, endpoint, obj);
      //console.log(JSON.stringify(res));
      let decodedProgram = base64.decode(res.data.code);
    }
  };

  const onEditorMount = (newEditor, monaco) => {
    editorRef.current = newEditor;
    setMonaco(monaco);
  };

  return (
    // <div className="EditorBoxContainer">
    //   <div className="EditorBoxCss">
    <Paper
      elevation={0}
      sx={{
        p: 2,
        pt: 1,
        width: "100%",
        maxWidth: "100%",
        // maxHeight: "100vh",
        border: "none",
        overflowY: "hidden",
      }}
    >
      {props.editMode && (
        <Tabs activeKey={props.activeTab} onChange={props.setActiveTab}>
          <TabPane tab="User Code" key="userCode" />
          <TabPane tab="Driver Code" key="driverCode" />
        </Tabs>
      )}
      <div
        className="editor-header-menu-container"
        style={{
          transition: "all ease-in-out .8s",
        }}
      >
        <Select
          defaultValue={props.selectedLanguage}
          onChange={selectLanguage}
          className="mr-1"
          size="small"
        >
          <Option value="motoko">Motoko</Option>
          {/* <Option value="cpp">C/C++</Option>
                <Option value="python">Python</Option>
                <Option value="java">Java</Option> */}
        </Select>
        <Tooltip
          title={
            props.editMode ? "Execute driver + user code" : "Execute your code"
          }
        >
          <Button
            className="mr-1 icon-button"
            type="primary"
            onClick={call_to_runtest_function}
            ghost
            size="small"
            style={{
              display: "flex",
              justifyContent: props.runningTestCases ? "center" : "flex-start",
              minWidth: "3rem",
              gap: 1,
            }}
            icon={
              props.runningTestCases ? (
                <></>
              ) : (
                <PlayArrowIcon
                  style={{
                    fontSize: "1.2rem",
                    margin: 0,
                    padding: 0,
                  }}
                />
              )
            }
            disabled={props.runningTestCases}
          >
            {props.runningTestCases ? <CircularProgress size={15} /> : "Run"}
          </Button>
        </Tooltip>
        <FullScreenToggleButtons
          isFullScreen={props.fullScreenEditor}
          onToggle={props.toggleFullScreenEditor}
        />
        {props.showChatButton && !props.showChat && (
          <Button
            className="mr-1 icon-button"
            type="primary"
            onClick={() => props.toggleChat()}
            ghost
            size="small"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "2rem",
              gap: "4px",
              marginLeft: ".3rem",
            }}
          >
            <KeyboardDoubleArrowLeftIcon
              style={{
                fontSize: "1rem",
              }}
            />
            <span>AI Assistant</span>
          </Button>
        )}
        {/* <Button
                className="submit-button icon-button"
                type="primary"
                onClick={call_to_submit_function}
                size="small"
              >
                <CloudUploadIcon
                  style={{
                    fontSize: "1.2rem",
                    marginRight: ".3rem",
                  }}
                />
                Submit
              </Button> */}
      </div>
      <Editor
        language={props.selectedLanguage}
        defaultLanguage="motoko"
        value={
          props.editMode
            ? props.activeTab === "driverCode"
              ? props.driverProgram
              : props.userProgram
            : props.userProgram
        }
        onChange={onChange}
        height="100%"
        width="100%"
        beforeMount={beforeMountConfiguration}
        onMount={onEditorMount}
        options={{
          minimap: { enabled: false },
          wordWrap: "on",
          wrappingIndent: "indent",
          scrollBeyondLastLine: true, // Enable scrolling beyond the last line
          fontSize: 14,
          tabSize: 2,
          fixedOverflowWidgets: true,
        }}
      />
    </Paper>
  );
};

export default EditorBox;
