import { Tooltip, Button } from "antd";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import "../../css/style.css";

const FullScreenToggleButtons = ({ isFullScreen, onToggle }) => {
  return (
    <Tooltip title={isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}>
      <Button
        type="primary"
        style={{}}
        icon={
          isFullScreen ? (
            <FullscreenExitIcon className="full-screen-icon" />
          ) : (
            <FullscreenIcon className="full-screen-icon" />
          )
        }
        onClick={onToggle}
        className="icon-button full-screen-toggle-button"
      />
    </Tooltip>
  );
};

export default FullScreenToggleButtons;
