import React from "react";
import { Tabs } from "antd";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Rich text editor styles

import CodeBlock from "./CodeBlock";
import { Chip, TextField } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }], // Header styles
    ["bold", "italic", "underline", "strike"], // Text styles
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    [{ indent: "-1" }, { indent: "+1" }], // Indentation
    [{ align: [] }], // Alignment options
    ["blockquote", "code-block"], // Quote and Code block
    ["link", "image"], // Links and Images
    ["clean"], // Remove formatting
  ],
};

const { TabPane } = Tabs;

function QuestionBox(props) {
  const callback = (key) => {
    console.log(key);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: props.fullScreenEditor ? 0 : 2,
        minWidth: props.fullScreenEditor ? "0%" : "100%",
        width: props.fullScreenEditor ? "0%" : "100%",
        maxWidth: props.fullScreenEditor ? "0%" : "100%",
        border: "none",
        overflowY: "auto !important",
        height: "100%",
      }}
    >
      {props.editMode ? (
        <TextField
          size="small"
          value={props.questionData?.name}
          InputProps={{
            style: {
              fontWeight: 500,
              fontSize: "1.1rem",
              padding: 0,
              margin: 0,
            },
          }}
          placeholder="Question title"
          style={{
            width: "100%",
          }}
          key="name"
          onChange={(e) =>
            props.handleQuestionDetailsChange("name", e.target.value)
          }
          error={!!!props.questionData?.name?.trim()?.length}
          required
        />
      ) : (
        <>
          <Typography variant="h5">{props.questionData?.name || ""}</Typography>
          <div
            style={{
              display: "flex",
              gap: ".8rem",
              marginTop: ".4rem",
              alignItems: "center",
            }}
          >
            <Chip
              label={props.questionData.difficulty
                ?.replace("_", " ")
                .toLowerCase()
                .replace(/^\w/, (c) => c.toUpperCase())}
              variant="filled"
              size="small"
              style={{
                color: "#00A300",
                fontSize: ".7rem",
              }}
            />
            {props.questionData.accepted ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DoneAllIcon
                  style={{
                    color: "#00A300",
                    fontSize: "1.2rem",
                  }}
                />
                <span
                  style={{
                    fontSize: ".8rem",
                    marginLeft: ".2rem",
                  }}
                >
                  Solved
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Question" key="1">
          {props.editMode ? (
            <ReactQuill
              theme="snow"
              key="questionDescription"
              value={props.questionData?.questionDescription}
              onChange={(value) =>
                props.handleQuestionDetailsChange("questionDescription", value)
              }
              modules={modules}
              style={{ height: "70vh" }}
            />
          ) : (
            <Typography
              dangerouslySetInnerHTML={{
                __html: props.questionData?.questionDescription || "",
              }}
            />
          )}
        </TabPane>
        <TabPane tab="Solution" key="2">
          {props.editMode ? (
            <ReactQuill
              theme="snow"
              value={props.questionData?.solution}
              key="solution"
              onChange={(value) =>
                props.handleQuestionDetailsChange("solution", value)
              }
              modules={modules}
              style={{
                height: "70vh",
              }}
            />
          ) : (
            <Typography
              dangerouslySetInnerHTML={{
                __html: props.questionData?.solution || "",
              }}
            />
          )}
        </TabPane>
      </Tabs>
    </Paper>
  );
}

export default QuestionBox;
