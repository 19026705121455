import React, { useEffect } from "react";

import LandingPageHeader from "./LandingPageHeader";
import HeroSection from "./HeroSection";
import DeveloperSection from "./DeveloperSection";
import BusinessLandingPagePricingSection from "./BusinessLandingPagePricingSection";
import Footer from "../Footer/index";

import "./landing-page-styles.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "./LandingPageHelper/home/assets/fonts/fonts.css"; // Import font styles
import "./LandingPageHelper/home/assets/css/all.min.css"; // Import all.min.css
import "./LandingPageHelper/home/assets/css/default.css"; // Import default.css
import "./LandingPageHelper/home/style.css"; // Import your style.css
import "./LandingPageHelper/home/assets/css/responsive.css"; // Import responsive.css
import "./LandingPageHelper/home/bootstrap.min.css"; // Import Bootstrap CSS
import "./LandingPageHelper/home/pricing.css"; // Import pricing.css

const heroSectionHeading = "Assess Developers, Save Time, and Hire the Best";
const heroSectionSubHeading =
  "We offer online coding assessments that are quick to set up, easy to use, and powered by AI for instant, accurate results. Focus on what matters—hiring the best talent for your team.";

const developerSectionHeading = "Editor";
const developerSectionSubHeading = `Evaluate developers seamlessly with our online coding assessments supporting Python, Java, and Node.js. Candidates can solve coding challenges in real-time, with syntax highlighting and instant feedback providing clear insights into their skills.
<br/>Accurately assess problem-solving and technical abilities with a simple, flexible tool tailored to your hiring needs.
  `;

const BusinessLandingPageFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>
          &copy; {new Date().getFullYear()} Zenithcode. All rights reserved.
        </p>
        <ul className="footer-links">
          <li>
            <span
              style={{
                cursor: "pointer",
                color: "#1062fb",
              }}
              onClick={() => {
                window.location.href = "mailto:zenithcode@mail.com";
              }}
            >
              Contact Us
            </span>
          </li>
        </ul>
      </div>
    </footer>
  );
};

const headerLinks = [
  {
    text: "Editor",
    link: "#developer",
  },
  {
    text: "Pricing",
    link: "#pricing",
  },
];

const BusinessLandingPage = () => {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.authenticated) {
      navigate("/dashboard");
    }
  }, [authState.authenticated]);

  return (
    <div className="landing-page-container">
      <LandingPageHeader headerLinks={headerLinks} />
      <HeroSection
        headingText={heroSectionHeading}
        subHeadingText={heroSectionSubHeading}
        hideCompanies={true}
        showGetInTouch={true}
      />
      <DeveloperSection
        headingText={developerSectionHeading}
        subHeadingText={developerSectionSubHeading}
      />
      <BusinessLandingPagePricingSection />
      <BusinessLandingPageFooter />
    </div>
  );
};

export default BusinessLandingPage;
