import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import { authActions } from "../../store/features/authSlice";

import { googleSignIn } from "../../utils/auth";

function GoogleLoginButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Capture referral code from URL and store it in localStorage
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referral_code");
    if (referralCode) {
      localStorage.setItem("referral_code", referralCode);
    }
  }, [location.search]);

  const responseGoogleSuccess = async (response) => {
    const googleToken = response.credential;

    // Pass referral code to backend if present
    const referralCode = localStorage.getItem("referral_code");
    const payload = {
      token_id: googleToken,
      referral_code: referralCode, // Include referral code if available
    };

    dispatch(authActions.authenticating());
    try {
      const result = await googleSignIn(payload);
      if (result.data.token) {
        dispatch(
          authActions.authenticate({ userDetails: result.data.userDetails })
        );
        navigate("/dashboard");
      } else {
        dispatch(authActions.logout());
        navigate("/");
      }
    } catch (error) {
      console.log("Error while logging in with Google", error);
      localStorage.removeItem("tokenId");
      dispatch(authActions.logout());
    }
  };

  const responseGoogleFailure = (response) => {
    // TODO: show alert to user
    console.log("Google response failure: ", response);
  };

  return (
    <div>
      <GoogleLogin
        buttonText="Login with Google"
        onSuccess={responseGoogleSuccess}
        onError={responseGoogleFailure}
      />
    </div>
  );
}

export default GoogleLoginButton;
