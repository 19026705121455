import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import Editor from "@monaco-editor/react";
import CodeIcon from "@mui/icons-material/Code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Select } from "antd";
import { useInView, motion } from "motion/react";
import mo from "motoko";

import { loadMoc } from "../../workers/mocShim";
import { loadBasePackage } from "../../workers/file";
import { configureMonaco } from "../../utils/monacoConfigs";
import "./landing-page-styles.css";

mo.clearPackages();
mo.installPackages({
  base: "dfinity/motoko-base/master/src", // import "mo:base/...";
});

const { Option } = Select;

const questions = [
  {
    title: "Hello World",
    question: `import Debug "mo:base/Debug";
import Text "mo:base/Text";

actor Main {
    public query func HelloWorld() : async Text {   

      // --> Try it out! Change the text below to create your own message.
      return "Hello ZenithCode!";

    };
};

let result = await Main.HelloWorld();
Debug.print(result);
    `,
  },
  {
    title: "Addition of two numbers",
    question: `import Debug "mo:base/Debug";
import Int "mo:base/Int"; // Import for Int functions
  
actor Main {
    // This function adds two integers and returns the result
    public query func addTwoNumbers(x: Int, y: Int) : async Int {
        return x + y; // Add the two numbers and return the sum
    };
};
  
// Example usage: Add 2 and 3, and print the result
let result = await Main.addTwoNumbers(2, 3); // Call the addTwoNumbers function
Debug.print(Int.toText(result)); // Convert the result to Text and print it
`,
  },
  {
    title: "Check if a number is even",
    question: `import Debug "mo:base/Debug";
import Int "mo:base/Int";
import Bool "mo:base/Bool";

actor Main {
  // This function checks if a number is even
  public query func isEven(x : Int) : async Bool {
    return x % 2 == 0; // Returns true if x is even, false otherwise
  }
};

// Example usage: Check if the number 10 is even and print the result
let result = await Main.isEven(10); // Call the isEven function with 10
Debug.print(Bool.toText(result)); // Convert the result (Bool) to Text and print it
`,
  },
];

const DeveloperSection = ({ headingText, subHeadingText }) => {
  const editorRef = useRef();
  const [motoko, setMotoko] = useState(null);
  const [code, setCode] = useState(questions.map((q) => q.question));
  const [monaco, setMonaco] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("motoko");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showCopied, setShowCopied] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [executionResults, setExecutionResults] = useState(null);
  const [executionError, setExecutionError] = useState(null);
  const [syntaxErrors, setSyntaxErrors] = useState([]);
  const ref = useRef(null);
  const isInView = useInView(ref, {});

  useEffect(() => {
    checkCodeMarkers(code[currentQuestion]);
  }, [code[currentQuestion]]);

  const handleCodeChange = (value, event) => {
    setCode((prev) => {
      const newCode = [...prev];
      newCode[currentQuestion] = value;
      return newCode;
    });
  };

  const onLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const handleSelectQuestion = (index) => {
    setCurrentQuestion(index);
    setExecuting(false);
    setExecutionError(null);
    setExecutionResults(null);
  };

  const loadMotoko = async () => {
    const Motoko = await loadMoc();
    setMotoko(Motoko);
    await loadBasePackage(Motoko);
  };

  const beforeMountConfiguration = async (monaco) => {
    await loadMotoko();
    configureMonaco(monaco);
  };

  const onEditorMount = (newEditor, monaco) => {
    editorRef.current = newEditor;
    setMonaco(monaco);
  };

  const setMarkers = (diags, codeModel, monaco) => {
    if (!monaco) return;
    const markers = [];
    diags.forEach((d) => {
      const severity =
        d.severity === 1
          ? monaco.MarkerSeverity.Error
          : monaco.MarkerSeverity.Warning;
      const marker = {
        startLineNumber: d.range.start.line + 1,
        startColumn: d.range.start.character + 1,
        endLineNumber: d.range.end.line + 1,
        endColumn: d.range.end.character + 1,
        message: d.message,
        code: d.code,
        severity,
      };
      markers.push(marker);
    });

    monaco.editor.setModelMarkers(codeModel, "moc", markers);
  };

  const checkCodeMarkers = async (code) => {
    if (!motoko || !monaco) return;
    motoko.saveFile("temp.mo", code);

    const checks = await motoko.check("temp.mo");
    const diags = checks.diagnostics;
    setSyntaxErrors(diags);

    // Handle the checks and update your markers
    const codeModel = monaco.editor.getModels()[0];
    setMarkers(diags, codeModel, monaco);
  };

  const textCopiedHandler = () => {
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  const executeCode = async () => {
    setExecutionError(null);
    setExecutionResults(null);

    if (
      syntaxErrors.length > 0 &&
      // if any syntax error is error and not warning
      syntaxErrors.some((error) => error.severity === 1)
    ) {
      setExecutionError(
        `Syntax Error:\n${syntaxErrors.map((error) => error.message).join("\n")}`
      );
      return;
    }

    setExecuting(true);
    try {
      const uniqueFileName =
        questions[currentQuestion].title?.toLowerCase().replace(" ", "_") +
        ".mo";
      mo.write(uniqueFileName, code[currentQuestion]);

      const result = mo.run(uniqueFileName);
      const stdout = result.stdout;
      const stderr = result.stderr;
      const _results = result.result;

      if (result.result?.error) {
        setExecutionError(stderr);
      } else {
        setExecutionError(null);
        setExecutionResults(stdout);
      }
    } catch (error) {
      console.log("Error: ", error);
      setExecutionError("An error occurred while executing the code.");
    } finally {
      setExecuting(false);
    }
  };

  return (
    <div className="lp-ds-container" id="developer">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }} // Starting state
        animate={isInView ? { opacity: 1, y: 0 } : {}} // Animate when in view
        transition={{ duration: 0.6, ease: "easeOut" }} // Animation settings
        className="lp-ds-content-box"
      >
        <div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className="lp-ds-header"
        >
          <CodeIcon
            style={{
              color: "green",
              fontSize: "3rem",
            }}
          />
          <h4>{headingText}</h4>
          <p dangerouslySetInnerHTML={{ __html: subHeadingText }} />
        </div>
        <div className="lp-ds-content">
          <div className="lp-ds-editor-container">
            <div className="lp-ds-editor-header">
              <div className="lp-ds-editor-header-left">
                <img
                  src="/zenithcode-logo.png"
                  alt="logo"
                  width={23}
                  height={23}
                />
                <Select
                  defaultValue={selectedLanguage}
                  onChange={onLanguageChange}
                  size="small"
                  className="lp-ds-editor-language-select"
                >
                  <Option value="motoko">Motoko</Option>
                  {/* <Option value="cpp">C/C++</Option>
                <Option value="python">Python</Option>
                <Option value="java">Java</Option> */}
                </Select>
              </div>
              <div className="lp-ds-editor-header-right">
                <Button
                  style={{
                    padding: "0.4rem .6rem",
                    border: "none",
                    cursor: "pointer",
                    textTransform: "none",
                    fontSize: ".7rem",
                    fontWeight: "bold",
                    boxShadow: "none",
                    background: showCopied ? "#80B583" : "#fff",
                    color: showCopied ? "white" : "#000",
                    transition: "all 0.3s ease",
                    borderRadius: "0.3rem",
                  }}
                  type="button"
                  size="small"
                  variant="contained"
                  startIcon={
                    <ContentCopyIcon
                      style={{
                        marginRight: "-3px",
                        fontSize: "1rem",
                      }}
                    />
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(code[currentQuestion]);
                    textCopiedHandler();
                  }}
                >
                  {showCopied ? "Copied!" : "Copy"}
                </Button>
                <Button
                  style={{
                    padding: "0.4rem .6rem",
                    border: "none",
                    cursor: "pointer",
                    textTransform: "none",
                    fontSize: ".7rem",
                    fontWeight: "bold",
                    boxShadow: "none",
                    borderRadius: "0.3rem",
                    transition: "all 0.3s ease",
                  }}
                  type="button"
                  size="small"
                  variant="contained"
                  startIcon={
                    executing ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <PlayArrowIcon
                        style={{
                          fontSize: "1rem",
                          marginRight: "-3px",
                        }}
                      />
                    )
                  }
                  onClick={() => executeCode()}
                  disabled={executing}
                >
                  Run
                </Button>
              </div>
            </div>
            <div className="lp-ds-editor">
              <Editor
                language={"monaco"}
                defaultLanguage="motoko"
                value={code[currentQuestion]}
                onChange={handleCodeChange}
                height="40vh"
                beforeMount={beforeMountConfiguration}
                onMount={onEditorMount}
                options={{
                  minimap: { enabled: false },
                  wordWrap: "on",
                  wrappingIndent: "indent",
                  scrollBeyondLastLine: true, // Enable scrolling beyond the last line
                  fontSize: 14,
                  tabSize: 2,
                  fixedOverflowWidgets: true,
                }}
                theme="vs-light"
                className="editor"
              />
              <div
                className="lp-ds-output-block"
                style={{
                  ...(executing || executionResults || executionError
                    ? {
                        opacity: 1,
                        transform: "translateY(0)",
                        padding: "1rem",
                        height: "auto",
                      }
                    : {
                        opacity: 1,
                        transform: "translateY(-40px)",
                        visibility: "hidden",
                      }),
                }}
              >
                <h4>Output:</h4>

                {/* Loader */}
                {executing && (
                  <CircularProgress size={24} style={{ margin: "auto" }} />
                )}

                {/* Success Output */}
                {executionResults && <pre>{executionResults}</pre>}

                {/* Error Output */}
                {executionError && (
                  <pre
                    style={{
                      background: "#ffeaea",
                      color: "#d32f2f",
                    }}
                  >
                    {executionError}
                  </pre>
                )}
              </div>
            </div>
          </div>
          <div className="lp-ds-questions">
            {questions.map((question, index) => (
              <div
                className="lp-ds-question"
                key={index}
                style={{
                  background: currentQuestion === index ? "#fff" : "",
                  boxShadow:
                    currentQuestion === index ? "0 0 10px #dadada" : "",
                }}
                onClick={() => handleSelectQuestion(index)}
              >
                <CodeIcon
                  style={{
                    fontSize: "1.2rem",
                    color: "#1062fb",
                    marginTop: ".1rem",
                    marginRight: "0.6rem",
                  }}
                />
                <span>{question.title}</span>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default DeveloperSection;
