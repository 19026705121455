import React from "react";
import { Checkbox, Button, Select } from "antd";
import { Autocomplete, TextField, Chip } from "@mui/material";

import "../../css/style.css";
import {
  companyTags,
  questionDifficulties,
  questionTags,
} from "../../static/Tags";

const { Option } = Select;

const QuestionMetadataField = ({ title, children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "8px",
        margin: ".8rem 0",
      }}
    >
      <h6
        style={{
          width: "8rem",
          minWidth: "8rem",
          color: "#4a4a4a",
          fontSize: ".9rem",
        }}
      >
        {title}:
      </h6>
      {children}
    </div>
  );
};

const QuestionMetadata = ({
  boxStyle,
  fullScreenEditor,
  questionMetadataWidth,
  difficulty,
  difficultyLevel,
  selectedCompanyTags,
  setDifficultyLevel,
  setDifficulty,
  setCompanyTags,
  setQuestionTags,
  selectedQuestionTags,
  visible,
  setVisible,
  comingSoon,
  setComingSoon,
  editing,
  upsertQuestionHandler,
}) => {
  return (
    <div
      style={{
        ...boxStyle,
        width: fullScreenEditor ? "0%" : `${questionMetadataWidth}%`,
        flexShrink: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <QuestionMetadataField title="Difficulty">
          <Select
            defaultValue={difficulty}
            style={{ width: 120 }}
            value={difficulty}
            onChange={(val) => setDifficulty(val)}
          >
            {questionDifficulties.map((questionDifficulty) => (
              <Option
                value={questionDifficulty.value}
                key={questionDifficulty.value}
              >
                {questionDifficulty.label}
              </Option>
            ))}
          </Select>
        </QuestionMetadataField>
        <QuestionMetadataField title="Difficulty level">
          <Select
            defaultValue={difficultyLevel}
            style={{ width: 120 }}
            value={difficultyLevel}
            onChange={(val) => setDifficultyLevel(val)}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Option value={value} key={value}>
                {value}
              </Option>
            ))}
          </Select>
        </QuestionMetadataField>
        <QuestionMetadataField title="Company tags">
          <Autocomplete
            multiple
            options={companyTags}
            key={"value"}
            getOptionLabel={(option) => option.label}
            value={selectedCompanyTags}
            onChange={(event, newValue) => setCompanyTags(newValue)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                return (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    key={option.value}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Company Tags" />
            )}
            style={{ width: "100%" }}
            size="small"
          />
        </QuestionMetadataField>
        <QuestionMetadataField title="Question tags">
          <Autocomplete
            multiple
            options={questionTags}
            getOptionLabel={(option) => option}
            value={selectedQuestionTags}
            onChange={(event, newValue) => setQuestionTags(newValue)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} key={option} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Question Tags" />
            )}
            style={{ width: "100%" }}
            size="small"
          />
        </QuestionMetadataField>
        <QuestionMetadataField title="Visible">
          <Checkbox
            onChange={(e) => setVisible(e.target.checked)}
            checked={visible}
            value={visible}
          />
        </QuestionMetadataField>
        <QuestionMetadataField title="Coming soon">
          <Checkbox
            onChange={(e) => setComingSoon(e.target.checked)}
            checked={comingSoon}
            value={comingSoon}
          />
        </QuestionMetadataField>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "auto",
          justifyContent: "flex-end",
          padding: "1rem",
        }}
      >
        {/* action buttons */}
        {editing ? (
          <Button type="primary" onClick={() => upsertQuestionHandler()}>
            Update
          </Button>
        ) : (
          <Button type="primary" onClick={() => upsertQuestionHandler()}>
            Add
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionMetadata;
