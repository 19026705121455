import React, { useEffect } from "react";
import LandingPage from "./components/LandingPage/index.js";
import BusinessLandingPage from "./components/LandingPage/BusinessLandingPage.js";
import { DashboardPage } from "./components/Dashboard/dashboard";
import { ProtectedRoute } from "./components/protectedroute";
import QuestionPage from "./components/Questions/questions";
// import TeamsPage from "./components/Teams/teams";
import EditQuestionPage from "./components/Admin/EditQuestionPage";
import AdminPanel from "./components/Admin/index.js";
import ProfilePage from "./components/Profile/profile";

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "./components/NotFound";
import { validateJWTToken } from "./utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/features/authSlice";
import useHideUnimportantErrors from "./customHooks/useHideUnimportantErrors";
import Header from "./components/header.js";
import "./css/style.css";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";

const App = () => {
  useHideUnimportantErrors();
  const theme = createTheme();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  const checkUserAuthentication = async () => {
    try {
      const result = await validateJWTToken();

      if (result) {
        dispatch(authActions.authenticating());
        dispatch(
          authActions.authenticate({
            userDetails: {
              email: result.email,
              name: result.name,
              picture: result.picture,
              role: result.role,
            },
          })
        );
      } else {
        dispatch(authActions.logout());
      }
    } catch (error) {
      console.log("Error while validating token", error);
      dispatch(authActions.logout());
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <RenderHeader authenticated={authState.authenticated} />
          <div className="app-container">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/business" element={<BusinessLandingPage />} />
              <Route path="/dashboard" element={<ProtectedRoute />}>
                <Route index element={<DashboardPage />} />
              </Route>
              {/* 
          <Route path="/teams" element={<ProtectedRoute />}>
            <Route index element={<TeamsPage />} />
          </Route> */}

              <Route path="/admin" element={<ProtectedRoute />}>
                <Route index element={<AdminPanel />} />
              </Route>
              <Route path="/admin/questions/:id" element={<ProtectedRoute />}>
                <Route index element={<EditQuestionPage />} />
              </Route>

              <Route path="/questions/:id/" element={<ProtectedRoute />}>
                <Route index element={<QuestionPage />} />
              </Route>

              <Route path="/profile" element={<ProtectedRoute />}>
                <Route index element={<ProfilePage />} />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const RenderHeader = ({ authenticated }) => {
  const location = useLocation();

  // Don't render the Header on the landing page
  if (location.pathname === "/" || location.pathname === "/business") {
    return null;
  }

  return <Header authenticated={authenticated} />;
};

export default App;
