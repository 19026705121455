import React, { useEffect } from "react";
import { useGoogleOneTapLogin } from "@react-oauth/google";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/features/authSlice";
import { googleSignIn } from "../../utils/auth";

import "./landing-page-styles.css";
import GoogleLoginButton from "./google_login_button";

const LinkButton = ({ text, link }) => {
  return (
    <a className="link-button" href={link} style={{ textDecoration: "none" }}>
      {text}
    </a>
  );
};

const LandingPageHeader = ({ headerLinks }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Capture referral code from URL and store it in localStorage
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referral_code");
    if (referralCode) {
      localStorage.setItem("referral_code", referralCode);
    }
  }, [location.search]);

  const responseGoogleSuccess = async (response) => {
    const googleToken = response.credential;

    // Pass referral code to backend if present
    const referralCode = localStorage.getItem("referral_code");
    const payload = {
      token_id: googleToken,
      referral_code: referralCode, // Include referral code if available
    };

    dispatch(authActions.authenticating());
    try {
      const result = await googleSignIn(payload);
      if (result.data.token) {
        dispatch(
          authActions.authenticate({ userDetails: result.data.userDetails })
        );
        navigate("/dashboard");
      } else {
        dispatch(authActions.logout());
        navigate("/");
      }
    } catch (error) {
      console.log("Error while logging in with Google", error);
      localStorage.clear();
      dispatch(authActions.logout());
    }
  };

  const responseGoogleFailure = (response) => {
    // TODO: show alert to user
    console.log("Google response failure: ", response);
  };

  const login = useGoogleOneTapLogin({
    onSuccess: responseGoogleSuccess,
    onError: responseGoogleFailure,
  });

  return (
    <div className="landing-page-header">
      {/* Site name and logo */}
      <div className="lp-header-site-name">
        <img src="/zenithcode-logo.png" alt="logo" />
        <h1>ZenithCode</h1>
      </div>
      {/* action items */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: "2rem",
          height: "100%",
        }}
      >
        {headerLinks.map((link, index) => (
          <LinkButton key={index} text={link.text} link={link.link} />
        ))}
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default LandingPageHeader;
