let mocPromise;

export const loadMoc = async () => {
  if (!mocPromise) {
    mocPromise = (async () => {
      const scriptUrl = "/moc.js";
      const response = await fetch(scriptUrl);
      const scriptContent = await response.text();

      // Execute the script content
      window.eval(scriptContent); // eval in the current context

      return window.Motoko; // Assuming Motoko is globally available after script execution
    })();
  }
  return mocPromise;
};
