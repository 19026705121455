import React, { useState, useEffect } from "react";
import QuestionBox from "../Questions/question_box";
import EditorBox from "../Questions/editor_box";
import "../../css/style.css";
import { useParams, useNavigate } from "react-router-dom";
import RenderCodeOutput from "../Questions/RenderCodeOutput";
import { CodeOutlined } from "@ant-design/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Tooltip } from "@mui/material";
import QuestionMetadata from "./QuestionMetadata";
import base64 from "react-native-base64";
import { Modal } from "antd";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import HorizontalResizer from "../Resizer/HorizontalResizer";
import { companyTags } from "../../static/Tags";

const initialQuestionBoxWidth = 25; // in percentage, 25% of total width
const initialEditorBoxWidth = 50;
const initialQuestionMetadataBoxWidth = 25;

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 64px)", // Adjust for header height
  background: "#fafafa",
  gap: "2px",
  padding: "8px",
  width: "100%",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  background: "#fff",
  border: "1px solid #ddd",
  borderRadius: "10px",
  margin: "2px 0px 2px 2px",
  position: "relative",
};

const resizableStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  width: "8px",
  cursor: "ew-resize",
  zIndex: 10,
};

const EditQuestionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [questionData, setQuestionData] = useState({
    id: isNaN(id) ? null : Number(id),
    name: "",
    detail: "",
    questionDescription: "",
    base64Question: "",
    solution: "",
  });

  const [selectedLanguage, setSelectedLanguage] = useState("motoko");
  const [fullScreenEditor, setFullScreenEditor] = useState(false);
  const [runningTestCases, setRunningTestCases] = useState(false);
  const [isResultMinimized, setIsResultMinimized] = useState(false);
  const [selectedQuestionTags, setSelectedQuestionTags] = useState(["string"]);
  const [selectedQuestionDifficulty, setSelectedQuestionDifficulty] =
    useState("easy");
  const [difficultyLevel, setDifficultyLevel] = useState(4);
  const [selectedCompanyTags, setSelectedCompanyTags] = useState([]);
  const [userProgram, setUserProgram] = useState({
    motoko: "",
  });
  const [driverProgram, setDriverProgram] = useState({
    motoko: "",
  });
  const [visible, setVisible] = useState(true);
  const [comingSoon, setComingSoon] = useState(false);
  const [output, setOutput] = useState({});
  const [editorActiveTab, setEditorActiveTab] = useState("driverCode");

  const [widths, setWidths] = useState({
    questionBox: initialQuestionBoxWidth,
    editorBox: initialEditorBoxWidth,
    questionMetadata: initialQuestionMetadataBoxWidth,
  });

  useEffect(() => {
    if (id !== "add") fetchQuestionDetails();
  }, []);

  const fetchQuestionDetails = async () => {
    const ip = Constants.ip;
    const port = Constants.port;
    const endpoint = "/fetch_question_payload_by_id";
    const obj = { id };

    const res = await Photon.post(ip, port, endpoint, obj);
    // this.setState({ question_id: res.data.question_id });
    // this.setState({ question_name: res.data.question_name });
    const data = res.data;
    const decodedQuestion = base64.decode(res.data.question_description);
    const decodedSolution = base64.decode(res.data.question_solution);
    setQuestionData({
      id: data.question_id,
      name: data.question_name,
      detail: data,
      questionDescription: decodedQuestion,
      base64Question: data.question_description,
      solution: decodedSolution,
    });
    const python = base64.decode(data.code_base64.python || "");
    const java = base64.decode(data.code_base64.java || "");
    const cpp = base64.decode(data.code_base64.cpp || "");
    const motoko = base64.decode(data.code_base64.motoko || "");

    const code = { python: python, java: java, cpp: cpp, motoko: motoko };

    setUserProgram(code);

    const python_driver = base64.decode(data.driver_code.python || "");
    const java_driver = base64.decode(data.driver_code.java || "");
    const cpp_driver = base64.decode(data.driver_code.cpp || "");
    const motokoDriver = base64.decode(data.driver_code.motoko || "");

    const driverCode = {
      python: python_driver,
      java: java_driver,
      cpp: cpp_driver,
      motoko: motokoDriver,
    };

    setVisible(data.visible);
    setComingSoon(data.coming_soon);
    setDriverProgram(driverCode);
    setDifficultyLevel(data.difficultylevel);
    setSelectedQuestionDifficulty(data.difficulty);
    setSelectedQuestionTags(data.tags);
    const companyTagsMapping = [];
    data.companytag?.forEach((tag) => {
      const found = companyTags.find((t) => t.value === tag);
      if (found) {
        companyTagsMapping.push(found);
      }
    });
    setSelectedCompanyTags(companyTagsMapping);
  };

  const handleResize = (type, delta) => {
    const totalWidth = 100; // Total width in percentage
    const minEditorWidth = 30; // Minimum width for the editor in percentage
    const minQuestionMeatdataWidth = 10; // Minimum width for the question metadata in percentage
    const minQuestionWidth = 10; // Minimum width for the question box in percentage

    if (type === "questionBox") {
      const newQuestionWidth = Math.max(
        minQuestionWidth,
        widths.questionBox + delta
      );
      const newEditorWidth =
        totalWidth - newQuestionWidth - widths.questionMetadata;

      if (newEditorWidth >= minEditorWidth) {
        setWidths({
          questionBox: newQuestionWidth,
          editorBox: newEditorWidth,
          questionMetadata: widths.questionMetadata,
        });
      }
    } else if (type === "editorBoxLeft") {
      const newQuestionWidth = Math.max(
        minQuestionWidth,
        widths.questionBox - delta
      );
      const newEditorWidth = widths.editorBox + delta;

      if (
        newEditorWidth >= minEditorWidth &&
        newQuestionWidth >= minQuestionWidth
      ) {
        setWidths({
          questionBox: newQuestionWidth,
          editorBox: newEditorWidth,
          questionMetadata: widths.questionMetadata,
        });
      }
    } else if (type === "editorBoxRight") {
      const newEditorWidth = Math.max(minEditorWidth, widths.editorBox + delta);
      const newQuestionMetadataWidth =
        totalWidth - widths.questionBox - newEditorWidth;

      if (newQuestionMetadataWidth >= minQuestionMeatdataWidth) {
        setWidths({
          questionBox: widths.questionBox,
          editorBox: newEditorWidth,
          questionMetadata: newQuestionMetadataWidth,
        });
      }
    } else if (type === "questionMetadata") {
      const newQuestionMetadataWidth = Math.max(
        minQuestionMeatdataWidth,
        widths.questionMetadata + delta
      );
      const newEditorWidth =
        totalWidth - widths.questionBox - newQuestionMetadataWidth;

      if (
        newEditorWidth >= minEditorWidth &&
        newQuestionMetadataWidth >= minQuestionMeatdataWidth
      ) {
        setWidths({
          questionBox: widths.questionBox,
          editorBox: newEditorWidth,
          questionMetadata: newQuestionMetadataWidth,
        });
      }
    }
  };

  const checkIfFormFilledCompletely = () => {
    if (questionData?.questionDescription?.trim()?.length < 30) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Question should have more than 30 characters";
      Modal.error({
        title,
        content,
      });
      return true;
    }
    if (!questionData?.name?.trim()?.length) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Question title is required";
      Modal.error({
        title,
        content,
      });
      return true;
    }
    if (questionData.solution?.trim()?.length < 30) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Solution should have more than 30 characters";
      Modal.error({
        title,
        content,
      });
      return true;
    }
    if (userProgram[selectedLanguage]?.length < 5) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = `${selectedLanguage} program should have more than 5 characters`;
      Modal.error({
        title,
        content,
      });
      return true;
    }
    if (driverProgram[selectedLanguage]?.length < 5) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = `${selectedLanguage} program should have more than 5 characters`;
      Modal.error({
        title,
        content,
      });
      return true;
    }

    // Commenting for now since we only support python3 as of now.
    // if (this.state.userProgram.java.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "java program should have more than 30 characters";
    //   Modal.error({
    // title,
    // content});
    //   return true;
    // }
    // if (this.state.driver_code.java.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "java program should have more than 30 characters";
    //   Modal.error({
    // title,
    // content});
    //   return true;
    // }
    // if (this.state.userProgram.cpp.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "cpp program should have more than 30 characters";
    //   Modal.error({
    // title,
    // content});
    //   return true;
    // }
    // if (this.state.driver_code.cpp.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "cpp program should have more than 30 characters";
    //   Modal.error({
    // title,
    // content});
    //   return true;
    // }

    if (selectedQuestionTags.length < 1) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "please select atleast one tag";
      Modal.error({
        title,
        content,
      });
      return true;
    }
    if (selectedCompanyTags.length < 1) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "please select atleast one company tag";
      Modal.error({
        title,
        content,
      });
      return true;
    }
    return false;
  };

  const upsertQuestionHandler = async () => {
    if (checkIfFormFilledCompletely()) return;
    let question_base64 = base64.encode(
      questionData.questionDescription?.trim()
    );
    let solution_base64 = base64.encode(questionData.solution?.trim());
    let python_base64 = base64.encode(userProgram.python || "");
    let java_base64 = base64.encode(userProgram.java || "");
    let cpp_base64 = base64.encode(userProgram.cpp || "");
    let motokoBase64 = base64.encode(userProgram.motoko || "");
    let python_base64_driver = base64.encode(driverProgram.python || "");
    let java_base64_driver = base64.encode(driverProgram.java || "");
    let cpp_base64_driver = base64.encode(driverProgram.cpp || "");
    let motokoBase64Driver = base64.encode(driverProgram.motoko || "");
    let payload = {
      question_name: questionData.name?.trim(),
      question_description: question_base64,
      question_solution: solution_base64,
      code_base64: {
        python: python_base64,
        java: java_base64,
        cpp: cpp_base64,
        motoko: motokoBase64,
      },
      driver_code: {
        python: python_base64_driver,
        java: java_base64_driver,
        cpp: cpp_base64_driver,
        motoko: motokoBase64Driver,
      },
      tags: selectedQuestionTags,
      difficulty: selectedQuestionDifficulty,
      difficultylevel: difficultyLevel,
      companytag: selectedCompanyTags?.map((tag) => tag.value),
      visible,
      coming_soon: comingSoon,
    };
    let ip = Constants.ip;
    let port = Constants.port;
    let endpoint;
    if (id === "add") {
      endpoint = "/insert_question_to_db";
    } else {
      payload.question_id = questionData.id;
      endpoint = "/update_question_by_id";
    }
    let obj = { payload };
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res));
    if (res.data.status) {
      let title = "SUCCESS";
      let content = res.data.message;
      Modal.success({
        title,
        content,
      });
    } else {
      let title = "FAILED";
      let content = res.data.message;
      Modal.error({
        title,
        content,
      });
    }
    // if (res.data.type == "insert") {
    //   this.set_values_to_default();
    // } else if (res.data.type == "update") {
    //   this.fetch_question_payload_fnx(this.state.question_id);
    // }
  };

  const handleQuestionDetailsChange = (key, value) => {
    setQuestionData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  const toggleFullScreenEditor = () => {
    setFullScreenEditor((currentState) => !currentState);
  };

  return (
    <div style={containerStyle}>
      {/* QuestionBox */}
      <div
        style={{
          ...boxStyle,
          width: fullScreenEditor ? "0%" : `${widths.questionBox}%`,
          flexShrink: 0,
        }}
      >
        <QuestionBox
          questionData={questionData}
          navigate={navigate}
          fullScreenEditor={fullScreenEditor}
          setFullScreenEditor={setFullScreenEditor}
          editMode={true}
          addQuestion={id === "add"}
          handleQuestionDetailsChange={handleQuestionDetailsChange}
        />
        <HorizontalResizer
          left={false}
          resizableStyle={resizableStyle}
          handleResize={handleResize}
          boxReference="questionBox"
        />
      </div>

      {/* EditorBox */}
      <div
        style={{
          ...boxStyle,
          width: fullScreenEditor ? "100%" : `${widths.editorBox}%`,
          flexShrink: 0,
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            background: "#fafafa ",
            gap: "4px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: isResultMinimized ? "100%" : "70%",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          >
            <EditorBox
              questionData={questionData}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              activeTab={editorActiveTab}
              setActiveTab={setEditorActiveTab}
              setUserProgram={setUserProgram}
              navigate={navigate}
              questionId={questionData?.id}
              showChat={true}
              toggleChat={() => {}}
              showChatButton={false}
              userProgram={userProgram[selectedLanguage]}
              driverProgram={driverProgram[selectedLanguage]}
              setDriverProgram={setDriverProgram}
              fullScreenEditor={fullScreenEditor}
              setFullScreenEditor={setFullScreenEditor}
              toggleFullScreenEditor={toggleFullScreenEditor}
              setOutput={setOutput}
              output={output}
              runningTestCases={runningTestCases}
              setRunningTestCases={setRunningTestCases}
              editMode={true}
              setIsResultMinimized={setIsResultMinimized}
              addQuestion={id === "add"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: isResultMinimized ? "0%" : "30%",
              background: "#fff",
              borderRadius: "10px",
              border: "1px solid #ddd",
              padding: isResultMinimized ? "0" : "8px 12px",
              overflow: "hidden",
              transition: "height 0.5s ease",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "20%",
                width: "100%",
                position: "sticky",
                top: 0,
              }}
            >
              <h6
                style={{ fontSize: ".9rem", cursor: "pointer" }}
                onClick={() => setIsResultMinimized(true)}
              >
                <CodeOutlined
                  style={{ marginRight: ".2rem", color: "green" }}
                />
                Code output
              </h6>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "1rem",
                  color: "gray",
                }}
                onClick={() => setIsResultMinimized(true)}
              >
                <Tooltip title="minimize output">
                  <KeyboardArrowDownIcon />
                </Tooltip>
              </button>
            </div>
            <RenderCodeOutput
              runningTestCases={runningTestCases}
              output={output}
            />
          </div>
          <div
            style={{
              position: "fixed",
              bottom: 10,
              width: fullScreenEditor ? "98%" : `${widths.editorBox - 2}%`,
              marginLeft: ".2rem",
              height: isResultMinimized ? "40px" : "0",
              background: "#fff",
              borderTop: "1px solid #ddd",
              display: isResultMinimized ? "flex" : "none",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              transition: "height 0.5s ease",
              padding: "12px 0 6px 12px",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
            onClick={() => setIsResultMinimized(false)}
          >
            <h6
              style={{
                fontSize: ".9rem",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <CodeOutlined style={{ color: "green" }} />
              Code output
              <Tooltip title="show output">
                <KeyboardArrowUpIcon style={{}} />
              </Tooltip>
            </h6>
          </div>
        </div>
        <HorizontalResizer
          left={false}
          resizableStyle={resizableStyle}
          handleResize={handleResize}
          boxReference="editorBoxLeft"
        />
        <HorizontalResizer
          left={false}
          resizableStyle={resizableStyle}
          handleResize={handleResize}
          boxReference="editorBoxRight"
        />
      </div>
      {/* Question metadata */}
      <QuestionMetadata
        boxStyle={boxStyle}
        fullScreenEditor={fullScreenEditor}
        questionMetadataWidth={widths.questionMetadata}
        difficulty={selectedQuestionDifficulty}
        difficultyLevel={difficultyLevel}
        selectedCompanyTags={selectedCompanyTags}
        selectedQuestionTags={selectedQuestionTags}
        setDifficulty={setSelectedQuestionDifficulty}
        setDifficultyLevel={setDifficultyLevel}
        setQuestionTags={setSelectedQuestionTags}
        setCompanyTags={setSelectedCompanyTags}
        visible={visible}
        setVisible={setVisible}
        comingSoon={comingSoon}
        setComingSoon={setComingSoon}
        editing={!!questionData?.id}
        addQuestion={id === "add"}
        upsertQuestionHandler={upsertQuestionHandler}
      />
    </div>
  );
};

export default EditQuestionPage;
