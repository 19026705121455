export const questionTags = [
  "string",
  "array",
  "hashmap",
  "tree",
  "dynamic programming",
  "linkedlist",
];

export const questionDifficulties = [
  { value: "very_easy", label: "very easy" },
  { value: "easy", label: "easy" },
  { value: "medium", label: "medium" },
  { value: "hard", label: "hard" },
];

export const companyTags = [
  { value: "dfinity", label: "Dfinity" },
  { value: "open_chat", label: "Open Chat" },
];

export const languages = ["motoko"];
