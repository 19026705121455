import React from "react";
import "./landing-page-styles.css";

const BusinessLandingPagePricingSection = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "4rem",
      }}
      id="pricing"
    >
      <h4
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        99 USD per month
        <br />
        Unlimited candidates and unlimited assessments
      </h4>
      <p
        style={{
          textAlign: "center",
          fontSize: "1.2rem",
          lineHeight: "1.6",
          color: "#666",
          maxWidth: "800px",
        }}
      >
        Access to our full library of coding questions (Python, Java, Node.js,
        and more). Instant, AI-powered grading for accurate results. Detailed
        candidate performance reports delivered instantly.
      </p>
    </div>
  );
};

export default BusinessLandingPagePricingSection;
