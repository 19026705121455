import React, { useRef, useState } from "react";
import Lottie from "lottie-react";
// import { Button } from "@mui/material";
import { useInView, motion } from "motion/react";

import groovyWalkAnimation from "./groovyWalk.json";
import "./landing-page-styles.css";
import { Button, TextField } from "@mui/material";
import Photon from "../Photon/photon";
import * as Constants from "../Config/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const companies = [
  {
    name: "Dfinity",
    logo: "/dfinity.png",
  },
];

const HeroSection = ({
  headingText,
  subHeadingText,
  hideCompanies = false,
  showGetInTouch = false,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {});

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [successText, setSuccessText] = useState(null);

  const handleEmailSubmit = async () => {
    try {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (email === "") {
        setEmailError("Enter a valid email address");
        return;
      }

      if (!emailRegex.test(email)) {
        setEmailError("Invalid email address format");
        return;
      }

      let ip = Constants.ip;
      let port = Constants.port;
      let endpoint = "/contact_request";
      let obj = { email };
      let res = await Photon.post(ip, port, endpoint, obj);

      // set success message
      setSuccessText(res.data.message);
      setEmail("");
      setEmailError(null);

      setTimeout(() => {
        setSuccessText(null);
      }, 5000);

      // show success message to user
    } catch (error) {
      if (error.response?.status === 400) {
        setEmailError(error.response?.data?.message);
        return;
      }

      console.log("Error while submitting email", error);
      setEmailError("Something went wrong. Please try again later.");
    }
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }} // Starting state
      animate={isInView ? { opacity: 1, y: 0 } : {}} // Animate when in view
      transition={{ duration: 0.6, ease: "easeIn" }} // Animation settings
      className="lp-hs-container"
    >
      <div className="lp-hs-content-box">
        <div className="lp-hs-header">
          <h1>
            {headingText}
            <img
              src={"/dfinity.png"}
              width={50}
              height={50}
              style={{
                verticalAlign: "middle",
                marginLeft: "1rem",
              }}
            />
          </h1>
          <p>{subHeadingText}</p>
          {showGetInTouch && (
            <div
              style={{
                display: "flex",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  label="Enter your email"
                  variant="outlined"
                  size="small"
                  placeholder="Enter your email"
                  value={email}
                  error={emailError}
                  style={{
                    width: "18rem",
                  }}
                  onChange={(e) => {
                    setEmailError(null);
                    setEmail(e.target.value?.trim());
                  }}
                />
                {emailError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.8rem",
                      padding: 0,
                      margin: ".4rem .7rem",
                    }}
                  >
                    {emailError}
                  </p>
                )}
                {successText && (
                  <p
                    style={{
                      color: "green",
                      fontSize: "0.8rem",
                      padding: 0,
                      margin: ".4rem .7rem",
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        fontSize: "1rem",
                        color: "green",
                        marginRight: ".4rem",
                      }}
                    />
                    {successText}
                  </p>
                )}
              </div>
              <Button
                className="lp-hs-prime-button"
                type="button"
                variant="contained"
                style={{
                  textTransform: "none",
                  maxHeight: "40px",
                  marginLeft: ".6rem",
                  boxShadow: "none",
                }}
                onClick={handleEmailSubmit}
              >
                Get in touch
              </Button>
            </div>
          )}
        </div>
        <div className="lp-hs-lottie-container">
          <Lottie animationData={groovyWalkAnimation} loop={true} />
        </div>
      </div>
      {!hideCompanies && (
        <div className="lp-hs-companies">
          <h2>Trusted by learners at top companies</h2>
          <div className="lp-hs-companies-logos">
            {companies.map((company) => (
              <img
                key={company.name}
                src={company.logo}
                alt={company.name}
                height={60}
              />
            ))}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default HeroSection;
