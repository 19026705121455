import React from "react";

import "./landing-page-styles.css";

const PricingSection = () => {
  return (
    <div className="lp-pricing-section">
      <stripe-pricing-table
        id="pricing"
        pricing-table-id="prctbl_1QZHFnEb96035Hyw4ln5Lp1t"
        publishable-key="pk_live_51QYzEIEb96035HywhEsMHmadIEuoOyq5enPafP0YrtOxppBAAV5nHXgkcJ1SiHrGTWyXTIlogQBPvlSwDDtiS2oh00DWG5EVKA"
      ></stripe-pricing-table>
    </div>
  );
};

export default PricingSection;
