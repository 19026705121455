import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Material UI imports
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InviteDialog from "./Dialogs/InviteDialog";
import "../css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/features/authSlice";

const pages = ["Dashboard"];
const settings = [
  "Dashboard",
  // "Profile",
  // "Invite",
  "Logout",
];

function Header(props) {
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const changePageLogout = () => {
    localStorage.removeItem("tokenId");
    dispatch(authActions.logout());
    navigate("/");
  };

  const toggleInviteDialog = () => {
    setShowInviteDialog((state) => !state);
  };

  const handleSettingsClick = (setting) => {
    switch (setting) {
      case "Profile":
        navigate("/profile");
        break;
      case "Teams":
        navigate("/teams");
        break;
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "Invite":
        toggleInviteDialog();
        break;
      case "Logout":
        changePageLogout();
        break;
      default:
        break;
    }
  };

  return (
    <AppBar position="static" color="primary">
      <InviteDialog open={showInviteDialog} toggleDialog={toggleInviteDialog} />
      <Toolbar disableGutters style={{ maxHeight: "2.5rem", padding: "0 2%" }}>
        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h7"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 15,
              display: { xs: "none", md: "flex" },
              // fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "inherit",
              alignItems: "center",
            }}
          >
            Zenith code
          </Typography>
          <div
            style={{
              fontSize: ".6rem",
              color: "#fafafa",
            }}
          >
            {location.pathname.startsWith("/admin") &&
            authState?.userDetails?.role === "ADMIN"
              ? "ADMIN"
              : ""}
          </div>
        </div>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <Link to={page === "teams" ? "/teams" : "/profile"}>
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Box>
        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          ZENITH CODE
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Link to={"/" + page?.toLowerCase()}>
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontSize: "1rem",
                  textTransform: "none",
                }}
              >
                {page}
              </Button>
            </Link>
          ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={authState.userDetails?.name}
                src={authState.userDetails?.picture}
                sx={{
                  width: "1.8rem",
                  height: "1.8rem",
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  handleCloseUserMenu();
                  handleSettingsClick(setting);
                }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
