import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";

const InviteDialog = ({ open, toggleDialog }) => {
  const theme = useTheme();
  const [inviteLink, setInviteLink] = useState("https://zenithcode.dev/");
  const [generating, setGenerating] = useState(false);
  const [copied, setCopied] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (open)
      // Generate invite link when the component is mounted
      generateInviteLink();
  }, [open]);

  const handleClose = () => {
    toggleDialog();
  };

  const generateInviteLink = async () => {
    setGenerating(true);
    try {
      const ip = Constants.ip;
      const port = Constants.port;
      const endpoint = `/generate_invite_code?email=${localStorage.getItem(
        "email"
      )}`;
      const res = await Photon.get(ip, port, endpoint);
      setInviteLink(
        `${Constants.frontend_ip}${Constants.port.lens || ""}/?referral_code=${
          res.data.inviteCode
        }`
      );
    } catch (err) {
      console.error(err);
    } finally {
      setGenerating(false);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);

    // Reset copied state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  if (generating) {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "1rem",
            minWidth: "400px",
            transition: "all 0.3s ease-in-out",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <h4
            style={{
              fontSize: "1rem",
              color: "#444",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            Generating invite link
          </h4>
          <CircularProgress size={24} />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        ".MuiDialog-paper": {
          borderRadius: "1rem",
          transition: "all 0.3s ease-in-out",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        Invite your friends to join zenith code
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Let your friends know about zenith code and invite them to join you on
          this platform.
        </DialogContentText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <h4
            style={{
              fontSize: "1rem",
              color: "#444",
              fontWeight: "500",
            }}
          >
            Invitation link:
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              type="text"
              value={inviteLink}
              readOnly
              style={{
                width: "80%",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
                cursor: "pointer",
                border: copied ? "1px solid #4caf50" : "1px solid #ccc",
                backgroundColor: copied ? "#e8f5e9" : "#fff",
                transition: "all 0.3s ease-in-out",
              }}
              onClick={handleCopyClick}
            >
              {copied ? (
                <>
                  <CheckCircleIcon
                    style={{
                      fontSize: "1.3rem",
                      color: "#4caf50",
                    }}
                  />
                  <span style={{ color: "#4caf50" }}>Copied!</span>
                </>
              ) : (
                <>
                  <ContentCopyIcon
                    style={{
                      fontSize: "1.3rem",
                    }}
                  />
                  <span>Copy</span>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteDialog;
